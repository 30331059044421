import axios from 'axios';

const BASE_API_URL = 'https://api.fitting-room.hosting.jfnet.de/api';

export const apiClient = axios.create({
  baseURL: BASE_API_URL,
  // withCredentials: true,
  // headers: {
  //   'Access-Control-Allow-Origin': '*',
  // },
});