import React, { useEffect, useRef, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Triangle, TailSpin } from 'react-loader-spinner';
import { debounce } from 'lodash'
import { Transition } from '@headlessui/react';

import ScrollingIcon from './assets/scrolling.png';

import ProductList from './components/ProductList';
import { FittingRoom } from './three-components/FittingRoom';
import { apiClient } from './services/axios';

// import PropTypes from 'prop-types';

import './index.css';

const avatarOptions = [
  {
    // file: 'https://dev.fitting-room.hosting.jfnet.de/images/body-type/human_10000.glb',
    file: require('./assets/drykorn/240223/avatar/Henry.glb'),
    img: '',
  },
];

function App({
  onhandleloading,
  onselectitem,
  onaddcart
}) {
  const fittingRoomRef = useRef(null);
  const canvasRef = useRef(null);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isResetting, setIsResetting] = useState(false);
  const [isOpenMobile, setIsMobileOpen] = useState(false);
  // const [isOpenSelectAvatar, setIsOpenSelectAvatar] = useState(false);
  const [selectedTab, setSelectedTab] = useState('top');
  const [isMobileScrollOverFooter, setIsMobileScrollOverFooter] = useState(false);
  // const [modelResults, setModelResults] = useState([]);
  const isScaling = useRef(false);
  const [cartItems, setCartItems] = useState([]);

  const [selectAvatar, setSelectAvater] = useState(avatarOptions[0]);

  const [topProducts, setTopProducts] = useState([]);
  const [selectTop, setSelectTop] = useState(null);

  const [bottomProducts, setBottomProducts] = useState([]);
  const [selectBottom, setSelectBottom] = useState(null);

  const [jacketProducts, setJacketProducts] = useState([]);
  const [selectJacket, setSelectJacket] = useState(null);
  const [isSelectJacket, setIsSelectJacket] = useState(false);

  const [vestProducts, setVestProducts] = useState([]);
  const [selectVest, setSelectVest] = useState(null);
  const [isSelectVest, setIsSelectVest] = useState(false);

  const selectedItems = useRef([]);
  const [limitSelectProductModelNumbers, setLimitSelectProductModelNumbers] = useState([]);

  const [inputQuantity, setInputQuantity] = useState({});

  const [initialized, setInitialized] = useState(false);
  const [isLoadingFittingRoomThree, setIsLoadingFittingRoomThree] = useState(true);
  const [isLoadingInitialized, setIsLoadingInitialized] = useState(true);
  // const [isLoadingProduct, setIsLoadingProduct] = useState(false);

  // const isMobileAndTabletControlVertical = useMemo(() => windowSize.innerWidth < 800, [windowSize.innerWidth]);

  function loadProducts() {
    setIsLoadingFittingRoomThree(true);
    setIsLoadingInitialized(true);
    const promise1 = apiClient.get('/products?category=tops')
    const promise2 = apiClient.get('/products?category=bottoms')

    Promise.all([promise1, promise2]).then((res) => {
      const [responseTop, responseBottom] = res;
      const filteredTopProducts = responseTop.data.slice().filter((item) => !item.isCombination)
      setTopProducts(filteredTopProducts);

      const filteredBottomProducts = responseBottom.data
      setBottomProducts(filteredBottomProducts);

      const filterdJacketProducts = responseTop.data.slice().filter((item) => item.isCombination && item.productCategory.toLowerCase() !== 'weste')
      setJacketProducts(filterdJacketProducts)

      const filterdVestProducts = responseTop.data.slice().filter((item) => item.isCombination && item.productCategory.toLowerCase() === 'weste')
      setVestProducts(filterdVestProducts)

      onSelectTop(filteredTopProducts[0])
      onSelectBottom(filteredBottomProducts[0])
      setInitQuantity();
    })
  }

  function setInitQuantity() {
    let inputQty = {};
    topProducts.forEach((item) => {
      inputQty = {
        ...inputQty,
        [item.identification]: 1
      }
    })
  }

  useEffect(() => {
    if (onhandleloading) {
      onhandleloading(isLoadingFittingRoomThree);
    }
  }, [onhandleloading, isLoadingFittingRoomThree])

  useEffect(() => {
    if (!initialized) {
      loadProducts();
      setInitialized(true);
      setIsLoadingInitialized(false);
    }
  }, [initialized])

  useEffect(() => {
    const footerSection = document.querySelector('.footer');

    const handleScroll = () => {
      const scrollHeight = window.scrollY + window.innerHeight;
      if (scrollHeight > footerSection.offsetTop) {
        setIsMobileScrollOverFooter(true);
      } else {
        setIsMobileScrollOverFooter(false);
      }
    };

    function handleWindowResize() {
      setIsMobileOpen(false)
      setWindowSize(getWindowSize());
      const vh = window.innerHeight;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    handleWindowResize();

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  // function onSelectAvatar(e) {
  //   setIsOpenSelectAvatar(false)
  //   setSelectAvater(e);
  // }

  function setSelectedItemsState(position, itemData) {
    const currentTimeStamp = new Date();
    const foundedReplacePosition = selectedItems.current.find((item) => item.position === position);
    if (!foundedReplacePosition) {
      selectedItems.current =  [
        ...selectedItems.current,
        {
          ...itemData,
          timestamp: currentTimeStamp,
          position,
        }
      ];
      return;
    }

    const removeItems = selectedItems.current.slice().filter((item) => item.position !== position);
    selectedItems.current = [
      ...removeItems,
      {
        ...itemData,
        timestamp: currentTimeStamp,
        position,
      }
    ];
  }

  function onSelectTop(e, isFromDisableArea) {
    setIsLoadingFittingRoomThree(true);
    if (isSelectVest && !isFromDisableArea) {
      setIsLoadingFittingRoomThree(true);
      setSelectedItemsState('top', e);
      setSelectTop(e)
      const getSelectedVestItem = vestProducts.find((item) => item.identification === selectVest.identification);
      onSelectVest(getSelectedVestItem);
    } else if (isSelectJacket && !isFromDisableArea) {
      setIsLoadingFittingRoomThree(true);
      setSelectedItemsState('top', e);
      const getSelectedJacketItem = jacketProducts.find((item) => item.identification === selectJacket.identification);
      setSelectTop(e)
      onSelectJacket(getSelectedJacketItem);
    } else {
      removeJacket();
      removeVest();
      setLimitSelectProductModelNumbers([]);
      setSelectedItemsState('top', e);
      setIsMobileOpen(false)
      setSelectTop(e)
    }
    if (onselectitem && selectedItems.current.length) {
      onselectitem(selectedItems.current);
    }
  }
  function onSelectBottom(e) {
    setIsLoadingFittingRoomThree(true);
    setSelectedItemsState('bottom', e);
    setIsMobileOpen(false)
    setSelectBottom(e)
    if (onselectitem && selectedItems.current.length) {
      onselectitem(selectedItems.current);
    }
  }

  function onSelectJacket(e, isFromDisableArea) {
    setIsLoadingFittingRoomThree(true);
    setSelectedItemsState('jacket', e);
    // modelNumber=48841&materialNumber=420025
    if (isSelectVest && !isFromDisableArea) {
      apiClient.get(`/product-combinations?modelNumber=${e.modelNumber}&materialNumber=${e.materialNumber}`)
        .then((res) => {
          const getThreeCombinationItems = res.data.filter((item) => item.combination.length > 2);
          if (getThreeCombinationItems) {
            let possibleCombinations = [];
            res.data.forEach((item) => {
              possibleCombinations = [
                ...possibleCombinations,
                ...item.combination
              ];
            });
            possibleCombinations = [...new Set(possibleCombinations)];
            const foundedCombinableItem = getThreeCombinationItems
              .find((item) =>
                item.combination.includes(e.modelNumber.toString())
                && item.combination.includes(selectTop.modelNumber.toString())
                && item.combination.includes(selectVest.modelNumber.toString())
              )
            apiClient.get(`/product-combinations?modelNumber=${selectVest.modelNumber}&materialNumber=${selectVest.materialNumber}`)
              .then((vestRes) => {
                vestRes.data.forEach((item) => {
                  possibleCombinations = [
                    ...possibleCombinations,
                    ...item.combination
                  ];
                });
                possibleCombinations = [...new Set(possibleCombinations)];
                setLimitSelectProductModelNumbers(possibleCombinations);
                const getSelectedProductItems = selectedItems.current.slice().filter((item) => item.position !== 'bottom');
                // setSelectedItemsState('top', foundedFirstCombinableTopProduct);
                setSelectJacket({...e});
                setSelectVest({
                  ...selectVest,
                  selectedItems: getSelectedProductItems,
                  modelImage: foundedCombinableItem.imageName
                });
                setIsSelectJacket(true);
              })
          } else {
            // console.log("ERRRO HERE ??")
          }
        })
    } else {
      if (isFromDisableArea) {
        removeVest();
      }
      apiClient.get(`/product-combinations?modelNumber=${e.modelNumber}&materialNumber=${e.materialNumber}`)
        .then((res) => {
          const getOnlyTopAndJacketItems = selectedItems.current.slice().filter((item) => item.position !== 'bottom');
          const selectedItemsModelNumberStrings = getOnlyTopAndJacketItems.slice().map((item) => item.modelNumber.toString());
          const foundedCombination = res.data.length
            ? res.data.find(
              (item) => item.combination.every((combinationModelNumber) => selectedItemsModelNumberStrings.includes(combinationModelNumber.toString()))
            )
            : 'nodata'
          if (foundedCombination) {
            // get possible combination for the Jacket to make the data display correctly
            let possibleJacketCombinations = [];
            res.data.forEach((item) => {
              possibleJacketCombinations = [
                ...possibleJacketCombinations,
                ...item.combination
              ];
            });
            possibleJacketCombinations = [...new Set(possibleJacketCombinations)];

            // get another possible combination for the Shirt to make the data display correctly
            apiClient.get(`/product-combinations?modelNumber=${selectTop.modelNumber}&materialNumber=${selectTop.materialNumber}`)
              .then((topCombinationRes) => {
                let possibleShirtCombinations = [];
                topCombinationRes.data.forEach((item) => {
                  possibleShirtCombinations = [
                    ...possibleShirtCombinations,
                    ...item.combination
                  ];
                });
                possibleShirtCombinations = [...new Set(possibleShirtCombinations)];
                setSelectJacket({
                  ...e,
                  selectedItems: getOnlyTopAndJacketItems,
                  modelImage: foundedCombination.imageName
                });
                setLimitSelectProductModelNumbers([
                  ...possibleJacketCombinations,
                  ...possibleShirtCombinations
                ]);
                setIsSelectJacket(true);
              });
          } else if (foundedCombination !== 'nodata') {
            // console.log('NO DATA');
          }
        })
        .finally(() => {
          setIsMobileOpen(false)
        })
    }
    if (onselectitem && selectedItems.current.length) {
      onselectitem(selectedItems.current);
    }
  }

  function onSelectVest(e, isFromDisableArea) {
    setIsLoadingFittingRoomThree(true);
    setSelectedItemsState('vest', e);
    apiClient.get(`/product-combinations?modelNumber=${e.modelNumber}&materialNumber=${e.materialNumber}`)
      .then((res) => {
        if (isSelectJacket && !isFromDisableArea) {
          const getSelectTopItem = selectedItems.current.slice().find((item) => item.position === 'top');
          const foundedVestThatFitSelectedTopAndJacket = res.data.length
            ? res.data.find((item) => item.combination.includes(getSelectTopItem.modelNumber.toString()) && item.combination.includes(selectJacket.modelNumber.toString()))
            : undefined
          if (foundedVestThatFitSelectedTopAndJacket) {
            let possibleCombinations = [];
            const getOnlyVestItemsWithShirtAndJacket =  res.data.slice().filter((item) => item.combination.length === 3);
            getOnlyVestItemsWithShirtAndJacket.forEach((item) => {
              possibleCombinations = [
                ...possibleCombinations,
                ...item.combination
              ];
            });
            possibleCombinations = [...new Set(possibleCombinations)];
            const getOnlyTopAndJacketItems = selectedItems.current.slice().filter((item) => item.position !== 'bottom');
            setLimitSelectProductModelNumbers(possibleCombinations);
            setSelectVest({
              ...e,
              selectedItems: getOnlyTopAndJacketItems,
              modelImage: foundedVestThatFitSelectedTopAndJacket.imageName
            });
            setIsSelectVest(true);
          } else {
            let onlyVestAndShirtPossibleCombinations = [];
            const getOnlyVestItemsWithShirtAndJacket =  res.data.slice().filter((item) => item.combination.length === 3);
            getOnlyVestItemsWithShirtAndJacket.forEach((item) => {
              onlyVestAndShirtPossibleCombinations = [
                ...onlyVestAndShirtPossibleCombinations,
                ...item.combination
              ];
            });
            onlyVestAndShirtPossibleCombinations = [...new Set(onlyVestAndShirtPossibleCombinations)];
            const foundedFirstCombinableTopProduct = topProducts.find((topProd) => onlyVestAndShirtPossibleCombinations.includes(topProd.modelNumber.toString()));
            setSelectedItemsState('top', foundedFirstCombinableTopProduct);
            setSelectTop(foundedFirstCombinableTopProduct);

            const getOnlyTopAndJacketItems = selectedItems.current.slice().filter((item) => item.position !== 'bottom');
            setLimitSelectProductModelNumbers(onlyVestAndShirtPossibleCombinations);
            const foundedCombinationModelOfFoundedFirstCombinableTopAndJacketProduct = getOnlyVestItemsWithShirtAndJacket.find(
              (item) => item.combination.includes(foundedFirstCombinableTopProduct.modelNumber.toString())
            );
            setSelectVest({
              ...e,
              isThreeItems: true,
              selectedItems: getOnlyTopAndJacketItems,
              modelImage: foundedCombinationModelOfFoundedFirstCombinableTopAndJacketProduct.imageName
            });
            setIsSelectVest(true);
          }
        } else {
          if (isFromDisableArea) {
            removeJacket();
          }
          const seletetedTop = selectedItems.current.find((item) => item.position === 'top');
          const foundedVestThatFitSelectedTop = res.data.length
            ? res.data.find((item) => {
              const removeVestModelNumberFromCombinations = item.combination.filter((combiModelNumber) => combiModelNumber.toString() !== e.modelNumber.toString());
              return removeVestModelNumberFromCombinations[0] === seletetedTop.modelNumber.toString();
            })
            : undefined
          if (foundedVestThatFitSelectedTop) {
            let possibleCombinations = [];
            const getOnlyVestItemsWithShirt =  res.data.slice().filter((item) => item.combination.length === 2);
            getOnlyVestItemsWithShirt.forEach((item) => {
              possibleCombinations = [
                ...possibleCombinations,
                ...item.combination
              ];
            });
            possibleCombinations = [...new Set(possibleCombinations)];
            setLimitSelectProductModelNumbers(possibleCombinations);
            const getOnlyTopItems = selectedItems.current.slice().filter((item) => item.position !== 'bottom' && item.position !== 'jacket');
            setSelectVest({
              ...e,
              selectedItems: getOnlyTopItems,
              modelImage: foundedVestThatFitSelectedTop.imageName
            });
            setIsSelectVest(true);
          } else {
            // get another possible combination for the Vest to make the data display correctly
            let possibleCombinations = [];
            // Vest with shirt: combination length = 2
            const getOnlyVestItemsWithShirt =  res.data.slice().filter((item) => item.combination.length === 2);
            getOnlyVestItemsWithShirt.forEach((item) => {
              possibleCombinations = [
                ...possibleCombinations,
                ...item.combination
              ];
            });
            possibleCombinations = [...new Set(possibleCombinations)];
            const foundedFirstCombinableTopProduct = topProducts.find((top) => possibleCombinations.includes(top.modelNumber.toString()));
            if (foundedFirstCombinableTopProduct) {
              setLimitSelectProductModelNumbers(possibleCombinations);
              setSelectedItemsState('top', foundedFirstCombinableTopProduct);
              setSelectTop(foundedFirstCombinableTopProduct);
              const getOnlyTopItems = selectedItems.current.slice().filter((item) => item.position !== 'bottom' && item.position !== 'jacket');
              const getVestWithFoundedShirtModel = getOnlyVestItemsWithShirt.find((item) => item.combination.includes(foundedFirstCombinableTopProduct.modelNumber.toString()));
              setSelectVest({
                ...e,
                selectedItems: getOnlyTopItems,
                modelImage: getVestWithFoundedShirtModel.imageName
              });
              setIsSelectVest(true);
            } else {
              alert('No product can be matched with this. Please try again later.')
              setLimitSelectProductModelNumbers([]);
              removeVest();
              setIsLoadingFittingRoomThree(false);
            }
          }
        }
      })
      .catch(() => {
        setIsLoadingFittingRoomThree(false);
      }).finally(() => {
        setIsMobileOpen(false)
      });
    if (onselectitem && selectedItems.current.length) {
      onselectitem(selectedItems.current);
    }
  }

  function onCanvasCreated(state) {
    state.gl.localClippingEnabled = true;
    if (canvasRef.current) {
      canvasRef.current.addEventListener('wheel', checkScrollDirection);
      canvasRef.current.addEventListener('touchstart ', checkMobilePinchStart);
      canvasRef.current.addEventListener('touchmove  ', checkMobilePinchMove);
      canvasRef.current.addEventListener('touchend  ', checkMobilePinchEnd);
    }
  }
  function onSetIsLoading(e) {
    setIsLoadingFittingRoomThree(e);
  }

  function removeJacket() {
    setIsSelectJacket(false);
    setSelectJacket(null);
    selectedItems.current = selectedItems.current.slice().filter((item) => item.position !== 'jacket');
    if (onselectitem && selectedItems.current.length) {
      onselectitem(selectedItems.current);
    }
  }
  function removeVest() {
    setIsSelectVest(false);
    setSelectVest(null);
    selectedItems.current = selectedItems.current.slice().filter((item) => item.position !== 'vest');
    if (onselectitem && selectedItems.current.length) {
      onselectitem(selectedItems.current);
    }
  }

  const checkMobilePinchStart = function(event) {
    // pinchStart
    if (event.touches.length === 2) {
      isScaling.current = true;
    }
  }

  const checkMobilePinchEnd = function(event) {
    // pinchEnd
    if (isScaling.current) {
      isScaling.current = false;
    }
  }

  const checkMobilePinchMove = debounce(function(event) {
    // pinchMove
    if (isScaling.current) {
      if (Math.abs(fittingRoomRef.current.cameraControlsRef.current.distance) > 2.5 && Math.abs(fittingRoomRef.current.cameraControlsRef.current.distance) < 5 && !isResetting) {
        fittingRoomRef.current.cameraControlsRef.current.dolly(-4, true)
        setIsResetting(true)
        setTimeout(() => {
          fittingRoomRef.current.cameraControlsRef.current.setTarget(0, 0, 0, true)
          setIsResetting(false)
        }, 1000)
      } else {
        fittingRoomRef.current.cameraControlsRef.current.setTarget(0, 0, 0, true)
      }
    }
  }, 400)

  const checkScrollDirection = debounce(function(event) {
    if (!checkScrollDirectionIsUp(event)) {
      if (Math.abs(fittingRoomRef.current.cameraControlsRef.current.distance) > 2.5 && Math.abs(fittingRoomRef.current.cameraControlsRef.current.distance) < 5 && !isResetting) {
        fittingRoomRef.current.cameraControlsRef.current.dolly(-4, true)
        setIsResetting(true)
        setTimeout(() => {
          fittingRoomRef.current.cameraControlsRef.current.setTarget(0, 0, 0, true)
          setIsResetting(false)
        }, 1000)
      } else {
        fittingRoomRef.current.cameraControlsRef.current.setTarget(0, 0, 0, true)
      }
    }
  }, 400);

  function checkScrollDirectionIsUp(event) {
    if (event.wheelDelta) {
      return event.wheelDelta > 0;
    }
    return event.deltaY < 0;
  }

  function onAddToCart(size, item) {
    if (onaddcart) {
      onaddcart({
        size,
        item
      })
    }
    // setCartItems([
    //   ...cartItems,
    //   item,
    // ]);
  }

  // function removeFromCart(index) {
  //   const temp = cartItems.slice();
  //   temp.splice(index, 1);
  //   setCartItems([...temp]);
  // }

  function onUpdateSize({ item, size }) {
    // console.log('founded EAN', item.sizeChildren.find((v) => v.size === size))
  }

  function onUpdateQuantity ({ name, quantity }) {
    const temp = {
      ...inputQuantity,
      [name]: Number(quantity),
    };
    setInputQuantity(temp);
  }

  function ClothTabOption({
    tab,
    selectedTop,
    selectedBottom,
    selectedJacket,
    selectedVest,
    onSelectTop,
    onSelectBottom,
    onSelectJacket,
    onSelectVest,
    isSelectJacket,
    isSelectVest,
    removeJacket,
    removeVest,
    onAddToCart,
    onUpdateQuantity,
    onUpdateSize
  }) {
    if (tab === 'top') {
      return <ProductList
        itemKey="productCategory"
        items={topProducts}
        limitSelectProductModelNumbers={limitSelectProductModelNumbers}
        selected={selectedTop}
        onSelect={onSelectTop}
        onAddToCart={onAddToCart}
        onUpdateQuantity={onUpdateQuantity}
        onUpdateSize={onUpdateSize}
      />
    }
    if (tab === 'bottom') {
      return <ProductList
        itemKey="productCategory"
        items={bottomProducts}
        limitSelectProductModelNumbers={[]}
        selected={selectedBottom}
        onSelect={onSelectBottom}
        onAddToCart={onAddToCart}
        onUpdateQuantity={onUpdateQuantity}
        onUpdateSize={onUpdateSize}
      />
    }
    if (tab === 'jacket') {
      return <ProductList
        itemKey="productCategory"
        items={jacketProducts}
        limitSelectProductModelNumbers={limitSelectProductModelNumbers}
        selected={selectedJacket}
        onSelect={onSelectJacket}
        isSelectJacket={isSelectJacket}
        removeItem={removeJacket}
        onAddToCart={onAddToCart}
      />
    }
    if (tab === 'vest') {
      return <ProductList
        itemKey="productCategory"
        items={vestProducts}
        limitSelectProductModelNumbers={limitSelectProductModelNumbers}
        selected={selectedVest}
        onSelect={onSelectVest}
        isSelectVest={isSelectVest}
        removeItem={removeVest}
        onAddToCart={onAddToCart}
      />
    }
    return <ProductList
      itemKey="productCategory"
      items={[]}
      limitSelectProductModelNumbers={limitSelectProductModelNumbers}
      selected={selectedTop}
      onSelect={onSelectTop}
      onAddToCart={onAddToCart}
      onUpdateQuantity={onUpdateQuantity}
      onUpdateSize={onUpdateSize}
    />
  }

  return (
    <>
      <div
        ref={canvasRef}
        className="relative w-full overflow-hidden"
        style={{ height: 'var(--vh)' }}
      >
        <div
          className={
            "relative grid grid-cols-1 w-full h-full"
            + " md:grid-cols-[30%_70%]"
            + " lg:grid-cols-[35%_65%]"
            + " 2xl:grid-cols-[40%_60%]"
            + " 3xl:grid-cols-2"
          }
        >
          <div className="relative w-full">
            <Transition
              show={isLoadingFittingRoomThree || isLoadingInitialized}
              enter="transition-opacity duration-75 z-30"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150 z-30"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="w-full h-full absolute top-0 left-0 z-30">
                <div className="w-full h-full absolute top-0 left-0 z-30 bg-white" />
                <Triangle
                  height="80"
                  width="80"
                  color="#000000"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  visible={true}
                  wrapperClass="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40"
                />
              </div>
            </Transition>
            <FittingRoom
              ref={fittingRoomRef}
              selectAvatar={selectAvatar}
              selectTop={selectTop}
              selectBottom={selectBottom}
              selectJacket={selectJacket}
              selectVest={selectVest}
              isSelectJacket={isSelectJacket}
              isSelectVest={isSelectVest}
              isLoading={isLoadingFittingRoomThree}
              onCanvasCreated={onCanvasCreated}
              onSetIsLoading={onSetIsLoading}
            />
          </div>
          <div
            className={
              "hidden"
              + " md:flex relative flex-col w-full h-full border-l border-black z-10 overflow-hidden"
            }
          >
            <Transition
              show={isLoadingInitialized}
              enter="transition-opacity duration-75 z-30"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150 z-30"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="w-full h-full absolute top-0 left-0 z-30">
                <div className="w-full h-full absolute top-0 left-0 filter backdrop-blur" />
                <TailSpin
                  height="80"
                  width="80"
                  color="#000000"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperClass="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10"
                  visible={true}
                />
              </div>
            </Transition>
            <ul className="flex w-full my-4 pb-[5px]">
              <li
                className={
                  "relative min-w-[100px] py-1 text-center text-sm font-bold font-flama uppercase cursor-pointer"
                }
                onClick={() => setSelectedTab('top')}
              >
                <span className={
                  "relative before:absolute before:bottom-[-10px] before:left-0 before:w-full before:h-[2px] before:bg-theme-primary"
                  + (selectedTab === 'top' ? ' text-theme-primary before:opacity-100' : ' text-theme-black hover:text-black/50 before:opacity-0')
                }>
                  Top
                </span>
              </li>
              <li
                className={
                  "relative min-w-[100px] py-1 text-center text-sm font-bold font-flama uppercase cursor-pointer"
                }
                onClick={() => setSelectedTab('bottom')}
              >
                <span className={
                  "relative before:absolute before:bottom-[-10px] before:left-0 before:w-full before:h-[2px] before:bg-theme-primary"
                  + (selectedTab === 'bottom' ? ' text-theme-primary before:opacity-100' : ' text-theme-black hover:text-black/50 before:opacity-0')
                }>
                  Bottom
                </span>
              </li>
              <li
                className={
                  "relative min-w-[100px] py-1 text-center text-sm font-bold font-flama uppercase cursor-pointer"
                }
                onClick={() => setSelectedTab('jacket')}
              >
                <span className={
                  "relative before:absolute before:bottom-[-10px] before:left-0 before:w-full before:h-[2px] before:bg-theme-primary"
                  + (selectedTab === 'jacket' ? ' text-theme-primary before:opacity-100' : ' text-theme-black hover:text-black/50 before:opacity-0')
                }>
                  Jacket
                </span>
              </li>
              <li
                className={
                  "relative min-w-[100px] py-1 text-center text-sm font-bold font-flama uppercase cursor-pointer"
                }
                onClick={() => setSelectedTab('vest')}
              >
                <span className={
                  "relative before:absolute before:bottom-[-10px] before:left-0 before:w-full before:h-[2px] before:bg-theme-primary"
                  + (selectedTab === 'vest' ? ' text-theme-primary before:opacity-100' : ' text-theme-black hover:text-black/50 before:opacity-0')
                }>
                  Vest
                </span>
              </li>
            </ul>

            <Scrollbars
              className="w-full border-t border-black h-full"
            >
              <div className="md:px-[29px] md:py-5">
                <ClothTabOption
                  tab={selectedTab}
                  selectedTop={selectTop}
                  selectedBottom={selectBottom}
                  selectedJacket={selectJacket}
                  isSelectJacket={isSelectJacket}
                  selectedVest={selectVest}
                  isSelectVest={isSelectVest}
                  onSelectTop={onSelectTop}
                  onSelectBottom={onSelectBottom}
                  onSelectJacket={onSelectJacket}
                  onSelectVest={onSelectVest}
                  removeJacket={removeJacket}
                  removeVest={removeVest}
                  onAddToCart={onAddToCart}
                  onUpdateQuantity={onUpdateQuantity}
                  onUpdateSize={onUpdateSize}
                />
              </div>
            </Scrollbars>
          </div>
          <div className="md:hidden absolute left-0 bottom-0 w-full border-l border-black md:border-l-0 transition-all duration-200 ease-in-out transform z-40">
            <button
              className="
                absolute bottom-[60px] right-0 flex items-center justify-center
                w-[60px] h-[60px] bg-theme-gray-2 rounded-xl border z-20
              "
            >
              <img
                src={ScrollingIcon}
                alt="Scrolling area"
                className="w-1/2 h-auto object-contain"
              />
            </button>
            <button
              className="relative flex items-center justify-center w-full h-[50px] bg-theme-gray-2 rounded-t-xl z-50"
              onClick={() => setIsMobileOpen(!isOpenMobile)}
            >
              <p className='font-flama text-sm uppercase'>
                Produkt
              </p>
            </button>
            <div
              className={
                "absolute w-full transition-all duration-300 ease-in-out"
              }
              style={{
                transform: isOpenMobile ? 'translateY(-100%)': 'translateY(0)'
              }}
            >
              <Transition
                show={isLoadingInitialized}
                enter="transition-opacity duration-75 z-30"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150 z-30"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="w-full h-full absolute top-0 left-0 z-30">
                  <div className="w-full h-full absolute top-0 left-0 filter backdrop-blur" />
                  <TailSpin
                    height="80"
                    width="80"
                    color="#000000"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperClass="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10"
                    visible={true}
                  />
                </div>
              </Transition>
              <ul className="absolute top-0 left-0 z-50 flex w-full mb-4 py-4 bg-white overflow-x-auto">
                <li
                  className={
                    "relative min-w-[100px] py-1 text-center text-sm font-bold font-flama uppercase cursor-pointer"
                  }
                  onClick={() => setSelectedTab('top')}
                >
                  <span className={
                    "relative before:absolute before:bottom-[-10px] before:left-0 before:w-full before:h-[2px] before:bg-theme-primary"
                    + (selectedTab === 'top' ? ' text-theme-primary before:opacity-100' : ' text-theme-black hover:text-black/50 before:opacity-0')
                  }>
                    Top
                  </span>
                </li>
                <li
                  className={
                    "relative min-w-[100px] py-1 text-center text-sm font-bold font-flama uppercase cursor-pointer"
                  }
                  onClick={() => setSelectedTab('bottom')}
                >
                  <span className={
                    "relative before:absolute before:bottom-[-10px] before:left-0 before:w-full before:h-[2px] before:bg-theme-primary"
                    + (selectedTab === 'bottom' ? ' text-theme-primary before:opacity-100' : ' text-theme-black hover:text-black/50 before:opacity-0')
                  }>
                    Bottom
                  </span>
                </li>
                <li
                  className={
                    "relative min-w-[100px] py-1 text-center text-sm font-bold font-flama uppercase cursor-pointer"
                  }
                  onClick={() => setSelectedTab('jacket')}
                >
                  <span className={
                    "relative before:absolute before:bottom-[-10px] before:left-0 before:w-full before:h-[2px] before:bg-theme-primary"
                    + (selectedTab === 'jacket' ? ' text-theme-primary before:opacity-100' : ' text-theme-black hover:text-black/50 before:opacity-0')
                  }>
                    Jacket
                  </span>
                </li>
                <li
                  className={
                    "relative min-w-[100px] py-1 text-center text-sm font-bold font-flama uppercase cursor-pointer"
                  }
                  onClick={() => setSelectedTab('vest')}
                >
                  <span className={
                    "relative before:absolute before:bottom-[-10px] before:left-0 before:w-full before:h-[2px] before:bg-theme-primary"
                    + (selectedTab === 'vest' ? ' text-theme-primary before:opacity-100' : ' text-theme-black hover:text-black/50 before:opacity-0')
                  }>
                    Vest
                  </span>
                </li>
              </ul>
              <Scrollbars
                className="w-full h-full filter backdrop-blur"
                style={{
                  height: 'var(--vh)'
                }}
              >
                <div className="pt-16 pb-16">
                  <ClothTabOption
                    tab={selectedTab}
                    selectedTop={selectTop}
                    selectedBottom={selectBottom}
                    selectedJacket={selectJacket}
                    isSelectJacket={isSelectJacket}
                    selectedVest={selectVest}
                    isSelectVest={isSelectVest}
                    onSelectTop={onSelectTop}
                    onSelectBottom={onSelectBottom}
                    onSelectJacket={onSelectJacket}
                    onSelectVest={onSelectVest}
                    removeJacket={removeJacket}
                    removeVest={removeVest}
                    onAddToCart={onAddToCart}
                    onUpdateQuantity={onUpdateQuantity}
                    onUpdateSize={onUpdateSize}
                  />
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

// App.propTypes = {
//   'onhandleloading': PropTypes.func,
//   'onselectitem': PropTypes.func,
//   'onaddcart': PropTypes.func,
// };

export default App;