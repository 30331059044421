import React, { useEffect, useState, useRef, useMemo, Suspense, forwardRef, useImperativeHandle } from 'react'

import { Canvas, useLoader } from '@react-three/fiber'
import { PerspectiveCamera, useGLTF, useProgress, Environment } from '@react-three/drei'
import { sRGBEncoding, TextureLoader, LinearMipMapNearestFilter, NearestFilter, LinearMipMapLinearFilter, RepeatWrapping, MirroredRepeatWrapping } from 'three';
import { debounce } from 'lodash'

import { CameraControls } from './CameraControl';

function Avatar({ constant, modelFile }) {
  const { scene: avatarScene, nodes: avatarNodes } = useGLTF(modelFile)
  useEffect(() => {
    avatarScene.scale.setScalar(1.5)
    avatarScene.traverse(function(obj) {
      if (obj.isMesh) {
        obj.material.roughness = 0.8
        obj.castShadow = true
      }
    })
  }, [avatarScene])
  return (
    <primitive object={avatarScene} />
  )
}

function Bottom({ constant, modelFile }) {
  const { scene: trouScene, nodes: trouNodes } = useGLTF(modelFile)
  useEffect(() => {
    trouScene.scale.setScalar(1.5)
    trouScene.traverse(function(obj) {
      if (obj.isMesh) {
        obj.material.roughness = 0.8
        obj.castShadow = true
      }
    })
  }, [trouScene])
  return (
    <primitive object={trouScene} />
  )
}
function BottomWithUv({ constant, modelFile, modelUv }) {
  const { scene: trouScene, nodes: trouNodes } = useGLTF(modelFile)
  const uvMap = useLoader(TextureLoader, modelUv ? modelUv : '');
  useEffect(() => {
    if (!uvMap) {
      return;
    }
    trouScene.scale.setScalar(1.5)
    trouScene.traverse(function(obj) {
      if (obj.isMesh) {
        obj.material.roughness = 0.8
        obj.material.map = uvMap
        obj.material.map.flipY = false;
        obj.material.map.wrapS = RepeatWrapping;
        obj.material.map.wrapY = RepeatWrapping;
        obj.material.map.encoding = sRGBEncoding;
        obj.material.map.minFilter = LinearMipMapNearestFilter;
        obj.material.map.magFilter = NearestFilter;
      }
    })
  }, [trouScene, uvMap])
  return (
    <primitive object={trouScene} />
  )
}

function Top({ constant, modelFile }) {
  const { scene: shirtScene } = useGLTF(modelFile)
  useEffect(() => {
    shirtScene.scale.setScalar(1.5)
    shirtScene.traverse(function(obj) {
      if (obj.isMesh) {
        obj.material.roughness = 0.8
        obj.castShadow = true
      }
    })
  }, [shirtScene])
  return (
    <primitive object={shirtScene} />
  )
}
function TopWithUv({ constant, modelFile, modelUv }) {
  const { scene: shirtScene } = useGLTF(modelFile)
  const uvMap = useLoader(TextureLoader, modelUv ? modelUv : '');
  useEffect(() => {
    if (!uvMap) {
      return;
    }
    shirtScene.scale.setScalar(1.5)
    shirtScene.traverse(function(obj) {
      if (obj.isMesh) {
        obj.material.roughness = 0.8
        obj.material.map = uvMap
        obj.material.map.flipY = false;
        obj.material.map.wrapS = RepeatWrapping;
        obj.material.map.wrapY = RepeatWrapping;
        obj.material.map.encoding = sRGBEncoding;
        obj.material.map.minFilter = NearestFilter;
        obj.material.map.magFilter = LinearMipMapNearestFilter;
      }
    })
  }, [shirtScene, uvMap])
  return (
    <primitive object={shirtScene} />
  )
}

function JacketWithUv({ selectJacket }) {
  const { modelImage, colorImage: jacketColorImage, selectedItems } = selectJacket;
  const { scene: jacketScene, nodes: jacketNodes } = useGLTF(modelImage)

  const mapTextureLoaders = useRef([]);
  const loadedTextures = useRef([]);

  const removeJacketFromSeletedItems = useMemo(() => selectedItems.slice().filter((item) => item.modelNumber.toString() !== selectJacket.modelNumber.toString()), [selectedItems, selectJacket])

  useEffect(() => {
    loadedTextures.current = [];
    const loader = new TextureLoader();
    mapTextureLoaders.current = removeJacketFromSeletedItems.map((selectedItem) => loader.load(selectedItem.colorImage));
    const texture = Promise.all(
      mapTextureLoaders.current,
      (resolve, reject) => {
        resolve(texture);
      }).then(result => {
        loadedTextures.current = result;
      });
  }, [removeJacketFromSeletedItems])

  const jacketUvMap = useLoader(TextureLoader, jacketColorImage);
  useEffect(() => {
    if (loadedTextures.current.length === 0) {
      return
    }
    if (!jacketUvMap) {
      return;
    }

    jacketScene.scale.setScalar(1.5)
    jacketScene.traverse(function(obj) {
      if (obj.isMesh) {
        obj.material.roughness = 0.8


        const splitByUnderscore = obj.name.split('_');
        if (splitByUnderscore.length > 1) {
          const foundedMeshIndex = removeJacketFromSeletedItems.findIndex(
            (seItem) => seItem.modelName.toLowerCase().includes(splitByUnderscore[0].toLowerCase())
          );

          if (foundedMeshIndex < 0) {
            if (selectJacket.modelName.toString().toLowerCase().includes(obj.material.name.toString().toLowerCase())) {
              obj.material.map = jacketUvMap;
            } else {
              obj.material.map = loadedTextures.current[0];
            }

            obj.material.map.flipY = false;
            obj.material.map.wrapS = RepeatWrapping;
            obj.material.map.wrapY = RepeatWrapping;
            obj.material.map.encoding = sRGBEncoding;
            obj.material.map.minFilter = NearestFilter;
            obj.material.map.magFilter = LinearMipMapNearestFilter;
          } else {
            obj.material.map = loadedTextures.current[foundedMeshIndex];
            obj.material.map.wrapS = RepeatWrapping;
            obj.material.map.wrapY = RepeatWrapping;
            obj.material.map.flipY = false;
            obj.material.map.encoding = sRGBEncoding;
            obj.material.map.minFilter = NearestFilter;
            obj.material.map.magFilter = LinearMipMapNearestFilter;
          }
        } else {
          if (selectJacket.modelName.toString().toLowerCase().includes(obj.material.name.toString().toLowerCase())) {
            obj.material.map = jacketUvMap;
          } else {
            obj.material.map = loadedTextures.current[0];
          }
          obj.material.map.flipY = false;
          obj.material.map.wrapS = RepeatWrapping;
          obj.material.map.wrapY = RepeatWrapping;
          obj.material.map.encoding = sRGBEncoding;
          obj.material.map.minFilter = NearestFilter;
          obj.material.map.magFilter = LinearMipMapNearestFilter;
        }
      }
    })
  }, [loadedTextures.current])
  return (
    <primitive object={jacketScene} />
  )
}

function VestWithUv({ selectVest }) {
  const { modelImage, colorImage: vestColorImage, selectedItems } = selectVest;
  const { scene: vestScene } = useGLTF(modelImage)

  const mapTextureLoaders = useRef([]);
  const loadedTextures = useRef([]);

  const removeVestFromSeletedItems = useMemo(() => selectedItems.slice().filter((item) => item.modelNumber.toString() !== selectVest.modelNumber.toString()), [selectedItems, selectVest])

  useEffect(() => {
    loadedTextures.current = [];
    const loader = new TextureLoader();
    mapTextureLoaders.current = removeVestFromSeletedItems.map((selectedItem) => loader.load(selectedItem.colorImage));
    const texture = Promise.all(
      mapTextureLoaders.current,
      (resolve, reject) => {
        resolve(texture);
      }).then(result => {
        loadedTextures.current = result;
      });
  }, [removeVestFromSeletedItems])

  const vestUvMap = useLoader(TextureLoader, vestColorImage);
  useEffect(() => {
    if (loadedTextures.current.length === 0) {
      return
    }
    if (!vestUvMap) {
      return;
    }
    // if (!uvMapInside) {
    //   return;
    // }

    // Jacket TBC tiny
    vestScene.scale.setScalar(1.5)
    vestScene.traverse(function(obj) {
      if (obj.isMesh) {
        obj.material.roughness = 0.8

        const splitByUnderscore = obj.name.split('_');
        if (splitByUnderscore.length > 1) {
          const foundedMeshIndex = removeVestFromSeletedItems.findIndex(
            (seItem) => seItem.modelName.toLowerCase().includes(splitByUnderscore[0].toLowerCase())
          );

          if (foundedMeshIndex < 0) {
            if (selectVest.modelName.toString().toLowerCase().includes(obj.material.name.toString().toLowerCase())) {
              obj.material.map = vestUvMap;
            } else {
              obj.material.map = loadedTextures.current[0];
            }

            obj.material.map.flipY = false;
            obj.material.map.wrapS = RepeatWrapping;
            obj.material.map.wrapY = RepeatWrapping;
            obj.material.map.encoding = sRGBEncoding;
            obj.material.map.minFilter = NearestFilter;
            obj.material.map.magFilter = LinearMipMapNearestFilter;
          } else {
            obj.material.map = loadedTextures.current[foundedMeshIndex];
            obj.material.map.flipY = false;
            obj.material.map.wrapS = RepeatWrapping;
            obj.material.map.wrapY = RepeatWrapping;
            obj.material.map.encoding = sRGBEncoding;
            obj.material.map.minFilter = NearestFilter;
            obj.material.map.magFilter = LinearMipMapNearestFilter;
          }
        } else {
          if (selectVest.modelName.toString().toLowerCase().includes(obj.material.name.toString().toLowerCase())) {
            obj.material.map = vestUvMap;
          } else {
            obj.material.map = loadedTextures.current[0];
          }
          obj.material.map.flipY = false;
          obj.material.map.wrapS = RepeatWrapping;
          obj.material.map.wrapY = RepeatWrapping;
          obj.material.map.encoding = sRGBEncoding;
          obj.material.map.minFilter = NearestFilter;
          obj.material.map.magFilter = LinearMipMapNearestFilter;
        }
      }
    })
  }, [loadedTextures.current])
  return (
    <primitive object={vestScene} />
  )
}

function JacketVestWithUv({ selectVest }) {
  const { modelImage, selectedItems } = selectVest;
  const { scene: jacketVestScene, nodes } = useGLTF(modelImage)

  const jacketLoadedTexture = useRef();
  const topLoadedTexture = useRef();
  const vestLoadedTexture = useRef();

  const selectedTopItem = selectedItems.find((item) => item.position === 'top');
  const selectedJacketItem = selectedItems.find((item) => item.position === 'jacket');
  const selectedVestItem = selectedItems.find((item) => item.position === 'vest');

  useEffect(() => {
    const loader = new TextureLoader();
    topLoadedTexture.current = loader.load(selectedTopItem.colorImage);
  }, [topLoadedTexture, selectedTopItem]);

  useEffect(() => {
    const loader = new TextureLoader();
    jacketLoadedTexture.current = loader.load(selectedJacketItem.colorImage);
  }, [jacketLoadedTexture, selectedJacketItem]);

  useEffect(() => {
    const loader = new TextureLoader();
    vestLoadedTexture.current = loader.load(selectedVestItem.colorImage);
  }, [vestLoadedTexture, selectedVestItem]);

  useEffect(() => {
    if (!jacketLoadedTexture.current) {
      return
    }
    if (!topLoadedTexture.current) {
      return
    }
    if (!vestLoadedTexture.current) {
      return;
    }
    jacketVestScene.scale.setScalar(1.5)
    jacketVestScene.traverse(function(obj) {
      if (obj.isMesh) {
        obj.material.roughness = 0.8
        if (selectedVestItem && selectedVestItem.modelName.toString().toLowerCase().includes(obj.material.name.toString().toLowerCase())) {
          obj.material.map = vestLoadedTexture.current;
        } else if (selectedTopItem && selectedTopItem.modelName.toString().toLowerCase().includes(obj.material.name.toString().toLowerCase())) {
          obj.material.map = topLoadedTexture.current;
        } else if (selectedJacketItem && selectedJacketItem.modelName.toString().toLowerCase().includes(obj.material.name.toString().toLowerCase())) {
          obj.material.map = jacketLoadedTexture.current;
        }

        obj.material.map.flipY = false;
        obj.material.map.wrapS = RepeatWrapping;
        obj.material.map.wrapY = RepeatWrapping;
        obj.material.map.encoding = sRGBEncoding;
        obj.material.map.minFilter = NearestFilter;
        obj.material.map.magFilter = LinearMipMapNearestFilter;
        // const splitByUnderscore = obj.name.split('_');
        // if (splitByUnderscore.length > 1) {
        //   const foundedMeshIndex = removeJacketFromSeletedItems.findIndex(
        //     (seItem) => seItem.modelName.toLowerCase().includes(splitByUnderscore[0].toLowerCase())
        //   );

        //   if (foundedMeshIndex < 0) {
        //     obj.material.map.flipY = false;
        //     obj.material.map.encoding = sRGBEncoding;
        //     obj.material.map.minFilter = NearestFilter;
        //     obj.material.map.magFilter = LinearMipMapNearestFilter;
        //   } else {
        //     obj.material.map = loadedTextures.current[foundedMeshIndex];
        //     obj.material.map.flipY = false;
        //     obj.material.map.encoding = sRGBEncoding;
        //     obj.material.map.minFilter = NearestFilter;
        //     obj.material.map.magFilter = LinearMipMapNearestFilter;
        //   }
        // } else {
        //   if (selectVest.modelName.toString().toLowerCase().includes(obj.material.name.toString().toLowerCase())) {
        //     obj.material.map = vestUvMap;
        //   } else if (selectedTopItem.modelName.toString().toLowerCase().includes(obj.material.name.toString().toLowerCase())) {
        //     obj.material.map = topLoadedTexture.current[0];
        //   } else if (selectedJacketItem.modelName.toString().toLowerCase().includes(obj.material.name.toString().toLowerCase())) {
        //     obj.material.map = jacketLoadedTexture.current[0];
        //   }
        //   obj.material.map.flipY = false;
        //   obj.material.map.encoding = sRGBEncoding;
        //   obj.material.map.minFilter = NearestFilter;
        //   obj.material.map.magFilter = LinearMipMapNearestFilter;
        // }
      }
    })
  }, [jacketVestScene, vestLoadedTexture.current, jacketLoadedTexture.current, topLoadedTexture.current])
  return (
    <primitive object={jacketVestScene} />
  )
}

function TopPositionRender({
  isSelectJacket,
  isSelectVest,
  selectJacket,
  selectVest,
  selectTop,
}) {
  if (isSelectJacket && isSelectVest) {
    return selectVest && selectVest.modelImage
      ? <JacketVestWithUv selectVest={selectVest} />
      : <></>
  }

  if (isSelectJacket) {
    return selectJacket && selectJacket.modelImage
      ? <JacketWithUv selectJacket={selectJacket} />
      : <></>
  }

  if (isSelectVest) {
    return selectVest && selectVest.modelImage
      ? <VestWithUv selectVest={selectVest} />
      : <></>
  }

  return selectTop
    ? <TopWithUv
        modelFile={selectTop.modelImage}
        modelUv={selectTop.colorImage}
      />
    : <></>
}

export const FittingRoom = forwardRef(({
  selectAvatar,
  selectTop,
  selectBottom,
  selectJacket,
  selectVest,
  isSelectJacket,
  isSelectVest,
  isLoading,
  onCanvasCreated,
  onSetIsLoading
}, classRef) => {
  const cameraControlsRef = useRef(null);
  const camRef = useRef(null);

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const isMobileAndTabletControlVertical = useMemo(() => windowSize.innerWidth < 800 && window.matchMedia('(orientation: portrait)').matches, [windowSize.innerWidth]);

  const { progress } = useProgress()

  useImperativeHandle(classRef, () => ({
    cameraControlsRef
  }));

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
      const vh = window.innerHeight;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const setLoadingStatus = debounce(function() {
    if (progress === 100) {
      onSetIsLoading(false);
    }
  }, 2000)

  useEffect(() => {
    if (isLoading) {
      setLoadingStatus();
    }
  }, [isLoading, progress]);


  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  function StudioSoLights() {
    return (
      <>
        <Environment
          background={false}
          files={require('../assets/hdri/studio_s_02_1k.hdr')}
        />
        <ambientLight intensity={0.1} />
        <spotLight
          position={[4, 6, 6]}
          angle={0.9}
          intensity={0.3}
          castShadow={true}
        />
        <spotLight
          position={[0, 6, -6]}
          angle={0.9}
          intensity={0.1}
          castShadow={false}
        />
      </>
    )
  }

  function GroundAndFog() {
    return (
      <>
        <mesh rotation-x={Math.PI * -0.5} position={[0, -1.30, 0]} receiveShadow={true}>
          <planeGeometry args={[70, 70]} />
          <meshStandardMaterial color={'#ffffff'} metalness={0} roughness={0.89} />
        </mesh>
        <color attach="background" args={['#ffffff']} />
        <fog attach="fog" args={['#ffffff', 6, 14]} />
      </>
    );
  }

  return (
    <Canvas
      className="w-full h-full cursor-move"
      onCreated={onCanvasCreated}
      shadows
    >
      <PerspectiveCamera
        ref={camRef}
        fov={isMobileAndTabletControlVertical ? 40 : 30}
        near={0.1}
        far={100}
        makeDefault
        position={[0, 0.786969454578296, 6.955634977391649]}
      />
      <Suspense fallback={null}>
        <group position={[0, -1.3, 0]}>
          <Avatar modelFile={selectAvatar.file} />
          {
            selectBottom
              ? selectBottom.colorImage
                ? <BottomWithUv
                  modelFile={selectBottom.modelImage}
                  modelUv={selectBottom.colorImage}
                />
                : <Bottom modelFile={selectBottom.modelImage} />
              : <></>
          }
          <TopPositionRender
            isSelectJacket={isSelectJacket}
            isSelectVest={isSelectVest}
            selectVest={selectVest}
            selectJacket={selectJacket}
            selectTop={selectTop}
          />
        </group>
      </Suspense>
      <CameraControls
        ref={cameraControlsRef}
        camRef={camRef}
      />
      <GroundAndFog />
      <StudioSoLights />
      {/* <Perf position="top-left" /> */}
    </Canvas>
  );
});